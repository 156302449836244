import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  Row,
} from "reactstrap";
import { MakeApiCallAsync } from "../../../../helpers/ApiHelpers";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../../helpers/CommonHelper";
import Config from "../../../../helpers/Config";
import {
  makeAnyStringLengthShort,
  makeProductShortDescription,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../../helpers/ConversionHelper";
import GlobalEnums from "../../../../helpers/GlobalEnums";
import { showInfoMsg } from "../../../../helpers/ValidationHelper";
import logoImage from "../../../../resources/custom/images/v1hub_shop_logo.jpg";
import rootAction from "../../../../stateManagment/actions/rootAction";
import HorizaontalMenu from "./HorizaontalMenu";
import Languages from "./Languages";
import MenuUserSection from "./MenuUserSection";
import Wishlist from "./Wishlist";
import useMobileSize from "helpers/utils/isMobile";
import Currency from "./Currency";

const SearchHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMobileSize(991);
  const isMobile = useMobileSize(576);
  const [PopularCategoriesList, setPopularCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [categoryText, setCategoryText] = useState("All Category");
  const [SearchTerm, setSearchTerm] = useState("");
  const leftMenuState = useSelector(
    (state) => state.commonReducer.isLeftMenuSet
  );
  const [leftMenu, setLeftMenu] = useState(leftMenuState);
  const [langCode, setLangCode] = useState("");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const totalCartItems = useSelector(
    (state) => state.cartReducer.totalCartItems
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const { t } = useTranslation();

  const setLeftMenuManual = (value) => {
    setLeftMenu(value);
    dispatch(rootAction.commonAction.setLeftMenu(value));
  };

  const hangleCategory = (id, text) => {
    setSearchCategory(id ?? 0);
    setCategoryText(text);
  };

  const handleCart = (event) => {
    event.preventDefault();

    if (
      totalCartItems !== null &&
      totalCartItems !== null &&
      totalCartItems > 0
    ) {
      navigate("/" + getLanguageCodeFromSession() + "/cart");
    } else {
      showInfoMsg("No item exists in your cart");
    }
  };

  const submitSearchForm = (event) => {
    event.preventDefault();

    let categ = searchCategory ?? 0;
    if (
      SearchTerm !== null &&
      SearchTerm !== undefined &&
      SearchTerm.length > 1
    ) {
      let url = "/";
      if (categoryText !== undefined && categoryText !== "All Category") {
        url =
          "/" +
          getLanguageCodeFromSession() +
          "/all-products/" +
          categ +
          "/" +
          replaceWhiteSpacesWithDashSymbolInUrl(categoryText) +
          "?SearchTerm=" +
          SearchTerm;
      } else {
        url =
          "/" +
          getLanguageCodeFromSession() +
          "/all-products/" +
          categ +
          "/all-categories?SearchTerm=" +
          SearchTerm;
      }

      window.location.href = url;

      // navigate(url, { replace: true });
      // window.location.reload();
    } else {
      showInfoMsg("Enter something then search");
    }
  };

  useEffect(() => {
    // declare the data fetching function
    const getPopularCategories = async () => {
      //--Get language code
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: 1,
          PageSize: 30,
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_POPULAR_CATEGORIES"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        setPopularCategories(JSON.parse(response.data.data));
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["WebsiteSearchHeader"],
        null
      );

      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    // call the function
    getPopularCategories().catch(console.error);
  }, []);

  if (isTablet) {
    return (
      <div>
        <Row>
          <Col md="12">
            <div className="main-menu-block">
              <div className="logo-block">
                <a href="/#">
                  <Media src={logoImage} className="logo" alt="logo" />
                </a>
              </div>

              <div className="input-block">
                <div
                  className={
                    isMobile
                      ? `input-box add_to_cart right ${
                          openSearch ? "open-side" : ""
                        }`
                      : "input-box"
                  }
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="overlay"
                    onClick={() => setOpenSearch(!openSearch)}
                  />

                  <div className="cart-inner">
                    <div className="cart_top">
                      <div className="close-cart d-md-none">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenSearch(!openSearch)}
                        >
                          <img src="/images/icon-back.png" alt="back" />
                        </div>
                      </div>
                      <div>
                        <form
                          className="big-deal-form"
                          onSubmit={submitSearchForm}
                        >
                          <InputGroup>
                            <InputGroupText
                              onClick={(e) => submitSearchForm(e)}
                            >
                              <img src="/images/icon-search.png" alt="cart" />
                            </InputGroupText>
                            <Input
                              value={SearchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <ButtonDropdown
                              isOpen={dropdownOpen}
                              toggle={toggleDropDown}
                            >
                              <DropdownToggle key={"search-menu-toggle"} caret>
                                {categoryText}
                              </DropdownToggle>
                              <DropdownMenu key={"search-menu"}>
                                <DropdownItem
                                  onClick={(e) =>
                                    hangleCategory(0, "All Category")
                                  }
                                >
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "All",
                                        "lbl_allcatg_search_hdr"
                                      )
                                    : "All"}
                                </DropdownItem>

                                {PopularCategoriesList &&
                                  PopularCategoriesList?.filter(
                                    (x) =>
                                      x.ParentCategoryID !== null &&
                                      x.ParentCategoryID !== undefined
                                  )?.map((item, i) => (
                                    <DropdownItem
                                      key={i}
                                      onClick={(e) =>
                                        hangleCategory(
                                          item.CategoryID,
                                          makeProductShortDescription(
                                            langCode !== null &&
                                              langCode ==
                                                Config.LANG_CODES_ENUM["Arabic"]
                                              ? item.LocalizationJsonData !==
                                                  null &&
                                                item.LocalizationJsonData
                                                  .length > 0
                                                ? makeAnyStringLengthShort(
                                                    item.LocalizationJsonData?.find(
                                                      (l) =>
                                                        l.langId ==
                                                        Config
                                                          .LANG_CODES_IDS_ENUM[
                                                          "Arabic"
                                                        ]
                                                    )?.text,
                                                    14
                                                  )
                                                : makeAnyStringLengthShort(
                                                    item.Name,
                                                    14
                                                  )
                                              : makeAnyStringLengthShort(
                                                  item.Name,
                                                  14
                                                ),
                                            14
                                          )
                                        )
                                      }
                                    >
                                      {langCode !== null &&
                                      langCode ==
                                        Config.LANG_CODES_ENUM["Arabic"]
                                        ? item.LocalizationJsonData !== null &&
                                          item.LocalizationJsonData.length > 0
                                          ? makeAnyStringLengthShort(
                                              item.LocalizationJsonData?.find(
                                                (l) =>
                                                  l.langId ==
                                                  Config.LANG_CODES_IDS_ENUM[
                                                    "Arabic"
                                                  ]
                                              )?.text,
                                              17
                                            )
                                          : makeAnyStringLengthShort(
                                              item.Name,
                                              17
                                            )
                                        : makeAnyStringLengthShort(
                                            item.Name,
                                            17
                                          )}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </ButtonDropdown>
                          </InputGroup>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu-right-block">
                <Wishlist />
                <div className="cart-block cart-hover-div">
                  <div className="cart ">
                    <span className="cart-product">{totalCartItems ?? 0} </span>
                    <ul>
                      <li className="mobile-cart">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCart(e);
                          }}
                        >
                          <img src="/images/icon-cart.png" alt="cart" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="search-mobile d-sm-none"
                  onClick={() => setOpenSearch(!openSearch)}
                >
                  <img src="/images/icon-search.png" alt="cart" />
                </div>
                <MenuUserSection />
                <HorizaontalMenu />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div>
      <Row>
        <Col md="12">
          <div className="main-menu-block">
            <div className="d-flex align-items-center gap-4">
              <div className="logo-block">
                <a href="/#">
                  <Media src={logoImage} className="logo" alt="logo" />
                </a>
              </div>
              <HorizaontalMenu />{" "}
            </div>
            <div className="input-block">
              <div className="input-box">
                <form className="big-deal-form" onSubmit={submitSearchForm}>
                  <InputGroup>
                    <InputGroupText onClick={(e) => submitSearchForm(e)}>
                      {/* <span className="search">
                        <i className="fa fa-search"></i>
                      </span> */}

                      <img src="/images/icon-search.png" alt="cart" />
                    </InputGroupText>
                    <Input
                      value={SearchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <ButtonDropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropDown}
                    >
                      <DropdownToggle key={"search-menu-toggle"} caret>
                        {categoryText}
                      </DropdownToggle>
                      <DropdownMenu key={"search-menu"}>
                        <DropdownItem
                          onClick={(e) => hangleCategory(0, "All Category")}
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "All Category",
                                "lbl_allcatg_search_hdr"
                              )
                            : "All Category"}
                        </DropdownItem>

                        {PopularCategoriesList &&
                          PopularCategoriesList?.filter(
                            (x) =>
                              x.ParentCategoryID !== null &&
                              x.ParentCategoryID !== undefined
                          )?.map((item, i) => (
                            <DropdownItem
                              key={i}
                              onClick={(e) =>
                                hangleCategory(
                                  item.CategoryID,
                                  makeProductShortDescription(
                                    langCode !== null &&
                                      langCode ==
                                        Config.LANG_CODES_ENUM["Arabic"]
                                      ? item.LocalizationJsonData !== null &&
                                        item.LocalizationJsonData.length > 0
                                        ? makeAnyStringLengthShort(
                                            item.LocalizationJsonData?.find(
                                              (l) =>
                                                l.langId ==
                                                Config.LANG_CODES_IDS_ENUM[
                                                  "Arabic"
                                                ]
                                            )?.text,
                                            14
                                          )
                                        : makeAnyStringLengthShort(
                                            item.Name,
                                            14
                                          )
                                      : makeAnyStringLengthShort(item.Name, 14),
                                    14
                                  )
                                )
                              }
                            >
                              {langCode !== null &&
                              langCode == Config.LANG_CODES_ENUM["Arabic"]
                                ? item.LocalizationJsonData !== null &&
                                  item.LocalizationJsonData.length > 0
                                  ? makeAnyStringLengthShort(
                                      item.LocalizationJsonData?.find(
                                        (l) =>
                                          l.langId ==
                                          Config.LANG_CODES_IDS_ENUM["Arabic"]
                                      )?.text,
                                      17
                                    )
                                  : makeAnyStringLengthShort(item.Name, 17)
                                : makeAnyStringLengthShort(item.Name, 17)}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </InputGroup>
                </form>
              </div>
            </div>
            <div className="menu-right-block">
              <Wishlist />
              <div className="cart-block cart-hover-div">
                <div className="cart ">
                  <span className="cart-product">{totalCartItems ?? 0} </span>
                  <ul>
                    <li className="mobile-cart">
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCart(e);
                        }}
                      >
                        <img src="/images/icon-cart.png" alt="cart" />
                        {/* <i className="icon-shopping-cart "></i> */}
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className={`cart-item`}>
                  <h5>
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "shopping",
                          "lbl_search_hdr_shopping"
                        )
                      : "shopping"}
                  </h5>
                  <h5>
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "cart",
                          "lbl_search_hdr_cart"
                        )
                      : "cart"}
                  </h5>
                </div> */}
              </div>
              <MenuUserSection />
              <Languages />
              <Currency />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SearchHeader;
