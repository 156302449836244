import * as actionType from "../actionTypes";

const initialState = {
  guestUser: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_USER:
      return {
        ...state,
        user: action.payload,
        loggedIn: true,
      };
    case actionType.SET_GUEST_USER:
      return {
        ...state,
        guestUser: action.payload,
      };
    case actionType.LOG_OUT_USER:
      return {
        ...state,
        user: {},
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default userReducer;
