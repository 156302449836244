import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  Row,
} from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import {
  formatPrice,
  GetDefaultCurrencyCode,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  GetUserIdForHeader,
  replaceLoclizationLabel,
  ScrollIntoSpecificDiv,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  convertDateToDifferentFormats,
  getFileExtensionFromContentType,
  makeProductShortDescription,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import useMobileSize from "helpers/utils/isMobile";

const OrdersHistory = () => {
  const dispatch = useDispatch();
  const isTablet = useMobileSize(991);
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  // const [adminPanelBaseURL, setadminPanelBaseURL] = useState(
  //   Config["ADMIN_BASE_URL"]
  // );
  const [OrderMasterList, setOrderMasterList] = useState([]);
  const [OrderItemsDetailList, setOrderItemsDetailList] = useState([]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const [SearchTerm, setSearchTerm] = useState("");

  const currency = useRef(GetDefaultCurrencyCode());

  //-get login user from session
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const [isGuest, setIsGuest] = useState(
    loginUser === undefined || loginUser.UserID === undefined
  );

  const [viewOrderDetail, setViewOrderDetail] = useState(false);
  const [orderNumberActive, setOrderNumberActive] = useState("");

  // const onBack = () => {
  //   setViewOrderDetail(false);
  // };
  const getGuestOrderDetail = async (OrderId) => {
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));
    setViewOrderDetail(true);

    const headersDetail = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    let convertOrderId = OrderId.replace("#", "%23");
    let responseDetailOrderDetail = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_GUEST_ORDER_HISTORY_DETAIL"] +
        `/` +
        convertOrderId,
      Config.SUB_URL,
      null,
      headersDetail,
      "GET",
      true
    );
    setIsGuest(true);
    if (
      responseDetailOrderDetail !== null &&
      responseDetailOrderDetail.data !== null
    ) {
      await setOrderItemsDetailList(responseDetailOrderDetail.data.data);
    }

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);

    try {
      ScrollIntoSpecificDiv("order_item_detail", "smooth");
    } catch (err) {
      console.log(err.message);
    }
  };

  const submitSearchForm = (event) => {
    event.preventDefault();
    if (
      SearchTerm !== null &&
      SearchTerm !== undefined &&
      SearchTerm.length > 1
    ) {
      getGuestOrderDetail(SearchTerm);
    }
  };
  const viewOrderItemsDetails = async (OrderId) => {
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));
    setViewOrderDetail(true);

    const headersDetail = {
      // customerid: userData?.UserID,
      // customeremail: userData.EmailAddress,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramDetail = {
      requestParameters: {
        OrderId: OrderId,

        Currency: currency.current,
        recordValueJson: "[]",
      },
    };

    let responseDetailOrderDetail = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_CUSTOME_ORDER_HISTORY_DETAIL"],
      null,
      paramDetail,
      headersDetail,
      "POST",
      true
    );

    setIsGuest(false);
    if (
      responseDetailOrderDetail !== null &&
      responseDetailOrderDetail.data !== null
    ) {
      await setOrderItemsDetailList(
        JSON.parse(responseDetailOrderDetail.data.data)
      );
      console.log(JSON.parse(responseDetailOrderDetail.data.data));
    }

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);

    try {
      ScrollIntoSpecificDiv("order_item_detail", "smooth");
    } catch (err) {
      console.log(err.message);
    }
  };
  const downloadDigitalProduct = async (OrderItemID, ProductName) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let UserID = await GetUserIdForHeader();
    let fileUrl =
      Config["ADMIN_BASE_URL"] +
      Config["COMMON_CONTROLLER_SUB_URL"] +
      Config.END_POINT_NAMES["DOWNLOAD_DIGITAL_FILE"];
    fileUrl = `${fileUrl}/${OrderItemID ?? 0}/${UserID}`;

    const response = await fetch(fileUrl, {
      headers: headers,
    });

    const contentType = await response.headers.get("content-type");
    const fileExtension = getFileExtensionFromContentType(contentType);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    let FileName = replaceWhiteSpacesWithDashSymbolInUrl(ProductName);
    FileName = FileName ?? "Your_Digital_Product";
    link.setAttribute("download", FileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    // declare the data fetching function
    const getOrderHistoryMaster = async () => {
      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          UserId: loginUser.UserID,
          Currency: currency.current,
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CUSTOMER_ORDER_HISTORY_MASTER"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        await setOrderMasterList(JSON.parse(response.data.data));
        console.log(JSON.parse(response.data.data));
        viewOrderItemsDetails(JSON.parse(response.data.data)[0].OrderId);
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["OrdersHistory"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getOrderHistoryMaster().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Orders History</title>
        <meta name="description" content={siteTitle + " Orders History"} />
        <meta name="keywords" content="Orders History"></meta>
      </Helmet>

      <div className="">
        <SiteBreadcrumb title="Order History" parent="Home" />

        <section className="cart-section order-history section-big-py-space">
          <div className="custom-container">
            {!isTablet ? (
              <Row>
                <Col
                  lg="3"
                  xxl="2.5"
                  className="side-left-product-detail order-history-list"
                >
                  {OrderMasterList ? (
                    OrderMasterList?.map((item, idx) => (
                      <div
                        className={
                          item.OrderId === OrderItemsDetailList[0]?.OrderID
                            ? "order-history-item active"
                            : "order-history-item"
                        }
                        key={idx}
                        onClick={() => {
                          viewOrderItemsDetails(item.OrderId);
                        }}
                      >
                        <p className="order-no"> {item.OrderNumber} </p>
                        <div className="d-flex flex-row justify-content-between">
                          <span className="order-date">
                            {convertDateToDifferentFormats(
                              item.OrderDateUTC,
                              "dd-mm-yyyy"
                            )}
                          </span>
                          <span className="order-status">
                            {item.LatestStatusName}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Form onSubmit={submitSearchForm}>
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="Search OrderCode"
                            value={SearchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <InputGroupText onClick={(e) => submitSearchForm(e)}>
                            <img
                              src="/images/icon-search.png"
                              alt="cart"
                              style={{ cursor: "pointer", width: "24px" }}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  )}
                </Col>
                <Col
                  lg="9"
                  xxl="9.5"
                  xs="12"
                  className="ps-0 ps-md-3 side-right-product-detail"
                >
                  {OrderItemsDetailList !== undefined &&
                  OrderItemsDetailList !== null &&
                  OrderItemsDetailList.length > 0 ? (
                    <table
                      className="table cart-table table-responsive-xs"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/${getLanguageCodeFromSession()}/orders-history/${
                            OrderItemsDetailList[0].OrderID
                          }`
                        )
                      }
                    >
                      <thead>
                        <tr className="table-head">
                          <th scope="col" id="lbl_ordrhis_prd">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Image",
                                  "lbl_ordrhis_prd"
                                )
                              : "Image"}
                          </th>
                          <th scope="col" id="lbl_ordrhis_prdnme">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Free",
                                  "lbl_ordrhis_prdnme"
                                )
                              : "Name"}
                          </th>

                          <th scope="col" id="lbl_ordrhis_price">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Unit Price",
                                  "lbl_ordrhis_price"
                                )
                              : "Unit Price"}
                          </th>
                          <th scope="col" id="lbl_ordrhis_qty">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Quantity",
                                  "lbl_ordrhis_qty"
                                )
                              : "Quantity"}
                          </th>
                          <th scope="col" id="lbl_ordrhis_itmtotal">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  " Total",
                                  "lbl_ordrhis_itmtotal"
                                )
                              : " Total"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {OrderItemsDetailList?.map((itemDetail, idx) =>
                          isGuest ? (
                            <tr key={idx}>
                              <td>
                                <Media
                                  src={itemDetail.defaultImageUrl}
                                  alt="product"
                                  className="img-fluid"
                                />
                              </td>
                              <td>
                                <p>{itemDetail.productName}</p>
                              </td>
                              <td>
                                <p>{formatPrice(itemDetail.price)}</p>
                              </td>
                              <td>
                                <h4>{itemDetail.quantity}</h4>
                              </td>
                              <td>
                                <h4>
                                  {formatPrice(itemDetail.orderItemTotal)}
                                </h4>
                              </td>
                            </tr>
                          ) : (
                            <tr key={idx}>
                              <td>
                                <Media
                                  src={itemDetail.DefaultImageUrl}
                                  alt="product"
                                  className="img-fluid"
                                />
                              </td>
                              <td>
                                <p>{itemDetail.ProductName}</p>
                              </td>
                              <td>
                                <p>{formatPrice(itemDetail.Price)}</p>
                              </td>
                              <td>
                                <h4>{itemDetail.Quantity}</h4>
                              </td>
                              <td>
                                <h4>
                                  {formatPrice(itemDetail.OrderItemTotal)}
                                </h4>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <div> No Order History </div>
                  )}
                  <div className="cart-buttons">
                    <div className="pull-right">
                      <Link
                        to={`/${getLanguageCodeFromSession()}/`}
                        className="btn btn-normal btn-sm"
                        id="lbl_ordrhis_continue_ship"
                      >
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Continue Shopping",
                              "lbl_ordrhis_continue_ship"
                            )
                          : "Continue Shopping"}
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col
                  lg="3"
                  xxl="2.5"
                  className="side-left-product-detail order-history-list"
                >
                  {OrderMasterList?.map((item, idx) => (
                    <div
                      className={
                        item.OrderId === OrderItemsDetailList[0]?.OrderID
                          ? "order-history-item active"
                          : "order-history-item"
                      }
                      key={idx}
                      onClick={() => {
                        viewOrderItemsDetails(item.OrderId);
                        setOrderNumberActive(item.OrderNumber);
                      }}
                    >
                      <div className="d-flex flex-row justify-content-between">
                        <p className="order-no"> {item.OrderNumber} </p>
                        {item.OrderId === OrderItemsDetailList[0]?.OrderID ? (
                          <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        ) : (
                          <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        )}
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span className="order-date">
                          {convertDateToDifferentFormats(
                            item.OrderDateUTC,
                            "dd-mm-yyyy"
                          )}
                        </span>
                        <span className="order-status">
                          {item.LatestStatusName}
                        </span>
                      </div>
                      {item.OrderId === OrderItemsDetailList[0]?.OrderID && (
                        <div
                          className="history-detail-list"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/${getLanguageCodeFromSession()}/orders-history/${
                                OrderItemsDetailList[0].OrderID
                              }`
                            )
                          }
                        >
                          {OrderItemsDetailList !== undefined &&
                            OrderItemsDetailList !== null &&
                            OrderItemsDetailList.length > 0 &&
                            OrderItemsDetailList?.map((itemDetail, idx) => (
                              <div className="history-detail" key={idx}>
                                <Media
                                  src={itemDetail.DefaultImageUrl}
                                  alt="product"
                                  className="img-fluid"
                                />
                                <div
                                  className="d-flex flex-column justify-content-between gap-1"
                                  style={{ flex: 1 }}
                                >
                                  <p className="product-name">
                                    {makeProductShortDescription(
                                      itemDetail.ProductName,
                                      80
                                    )}
                                  </p>
                                  <p className="product-quantity">
                                    Quantity: {itemDetail.Quantity}
                                  </p>
                                  <p className="product-price">
                                    {formatPrice(
                                      itemDetail.Price,
                                      itemDetail.Currency
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          <div className="cart-buttons">
                            <Link
                              to={`/${getLanguageCodeFromSession()}/`}
                              className="btn btn-normal btn-outline"
                              id="lbl_ordrhis_continue_ship"
                              style={{ width: "100%" }}
                            >
                              Buy Again
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </Col>
              </Row>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default OrdersHistory;
