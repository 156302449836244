import useMobileSize from "helpers/utils/isMobile";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Col, Media, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import {
  formatPrice,
  GetDefaultCurrencyCode,
  getLanguageCodeFromSession,
  replaceLoclizationLabel,
  ScrollIntoSpecificDiv,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  convertDateToDifferentFormats,
  makeProductShortDescription,
} from "../../../helpers/ConversionHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { Divider } from "antd";

const OrdersHistoryDetail = () => {
  const dispatch = useDispatch();
  const isMobile = useMobileSize(576);
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  const [orderDetail, setOrderDetail] = useState();
  const [OrderItemsDetailList, setOrderItemsDetailList] = useState([]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const currency = useRef(GetDefaultCurrencyCode());
  //--set product id from url
  const params = useParams();
  const [orderId, setOrderId] = useState(params.order_id ?? 0);

  const subtotal = useCallback(() => {
    let total = 0;
    OrderItemsDetailList.forEach((item) => {
      total += item.unitPrice * item.quantity;
    });
    return total;
  }, [OrderItemsDetailList]);

  useEffect(() => {
    const getOrderDetail = async () => {
      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));
      const headersDetail = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let responseDetailOrderDetail = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_ORDER_HISTORY_DETAIL"] +
          `/${orderId}?currency=${currency.current}`,
        Config.SUB_URL,
        null,
        headersDetail,
        "GET",
        true
      );
      if (
        responseDetailOrderDetail !== null &&
        responseDetailOrderDetail.data !== null
      ) {
        await setOrderDetail(responseDetailOrderDetail.data.data);
        await setOrderItemsDetailList(
          responseDetailOrderDetail.data.data.products
        );
      }

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

      try {
        ScrollIntoSpecificDiv("order_item_detail", "smooth");
      } catch (err) {
        console.log(err.message);
      }
    };
    if (orderId > 0) {
      getOrderDetail();
    }
  }, [orderId, currency, dispatch]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Orders History Details</title>
        <meta
          name="description"
          content={siteTitle + " Orders History Details"}
        />
        <meta name="keywords" content="Orders History Details"></meta>
      </Helmet>

      <div className="">
        <SiteBreadcrumb
          title={
            orderDetail ? `Order ${orderDetail.orderNumber}` : "Order Details"
          }
          parent="Home / Orders History"
        />

        <section className="cart-section order-history section-big-py-space">
          <div className="custom-container" style={{ maxWidth: "1080px" }}>
            {orderDetail && (
              <Row className="order-detail">
                <Col xs="12">
                  <div className="order-info">
                    <div className="billing-summary">
                      <h4>Billing Information</h4>
                      <ul>
                        <li>
                          <span>Receiver: </span>{" "}
                          <span>{orderDetail.recipientName}</span>
                        </li>
                        <li>
                          <span>Phone: </span>
                          <span>
                            {orderDetail.phoneNumber
                              ? orderDetail.phoneNumber
                              : "--"}
                          </span>
                        </li>
                        <li>
                          <span>Email:</span>{" "}
                          <span>
                            {orderDetail.emailAddress
                              ? orderDetail.emailAddress
                              : "--"}
                          </span>
                        </li>
                        <li>
                          <span>Country: </span>{" "}
                          <span>{orderDetail.countryName || "--"}</span>
                        </li>
                        <li>
                          <span>State / Province: </span>
                          <span>{orderDetail.stateProvinceName || "--"}</span>
                        </li>
                        <li>
                          <span>City:</span>{" "}
                          <span>{orderDetail.cityName || "--"}</span>
                        </li>
                        <li>
                          <span>Postal Code:</span>{" "}
                          <span>{orderDetail.postalCode || "--"}</span>
                        </li>
                        <li>
                          <span>Address:</span>{" "}
                          <span>
                            {orderDetail.shippingAddress
                              ? orderDetail.shippingAddress
                              : "--"}
                          </span>
                        </li>
                        <li>
                          <span>Note:</span>{" "}
                          <span>
                            {orderDetail.orderNote
                              ? orderDetail.orderNote
                              : "--"}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col xs="12">
                  <div className="order-info">
                    <div className="order-summary">
                      <h4>Order Summary</h4>

                      <Divider
                        style={{ borderColor: "#dddddd", margin: "12px 0" }}
                      />
                      {!isMobile ? (
                        OrderItemsDetailList !== undefined &&
                        OrderItemsDetailList !== null &&
                        OrderItemsDetailList.length > 0 ? (
                          <table className="table cart-table table-responsive-xs">
                            <thead>
                              <tr className="table-head">
                                <th scope="col" id="lbl_ordrhis_prd">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Image",
                                        "lbl_ordrhis_prd"
                                      )
                                    : "Image"}
                                </th>
                                <th scope="col" id="lbl_ordrhis_prdnme">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Free",
                                        "lbl_ordrhis_prdnme"
                                      )
                                    : "Name"}
                                </th>

                                <th scope="col" id="lbl_ordrhis_price">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Unit Price",
                                        "lbl_ordrhis_price"
                                      )
                                    : "Unit Price"}
                                </th>
                                <th scope="col" id="lbl_ordrhis_qty">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Quantity",
                                        "lbl_ordrhis_qty"
                                      )
                                    : "Quantity"}
                                </th>
                                <th scope="col" id="lbl_ordrhis_itmtotal">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        " Total",
                                        "lbl_ordrhis_itmtotal"
                                      )
                                    : " Total"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {OrderItemsDetailList?.map((itemDetail, idx) => (
                                <tr key={idx}>
                                  <td style={{ width: "140px" }}>
                                    <Media
                                      src={itemDetail.productImage}
                                      alt="product"
                                      className="img-fluid"
                                    />
                                  </td>
                                  <td>
                                    <p>{itemDetail.productName}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {formatPrice(
                                        itemDetail.unitPrice,
                                        orderDetail.currency
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p>{itemDetail.quantity}</p>
                                  </td>
                                  <td>
                                    <h4>
                                      {formatPrice(
                                        itemDetail.unitPrice *
                                          itemDetail.quantity,
                                        orderDetail.currency
                                      )}
                                    </h4>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div> No Order History </div>
                        )
                      ) : (
                        <div className="history-detail-list">
                          {OrderItemsDetailList !== undefined &&
                            OrderItemsDetailList !== null &&
                            OrderItemsDetailList.length > 0 &&
                            OrderItemsDetailList?.map((itemDetail, idx) => (
                              <div className="history-detail" key={idx}>
                                <Media
                                  src={itemDetail.productImage}
                                  alt="product"
                                  className="img-fluid"
                                />
                                <div
                                  className="d-flex flex-column justify-content-between"
                                  style={{ flex: 1 }}
                                >
                                  <p className="product-name">
                                    {makeProductShortDescription(
                                      itemDetail.productName,
                                      80
                                    )}
                                  </p>
                                  <p className="product-quantity">
                                    Quantity: {itemDetail.quantity}
                                  </p>
                                  <p className="product-price">
                                    {formatPrice(
                                      itemDetail.unitPrice,
                                      itemDetail.currency
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                      <ul>
                        <li>
                          <span>Created Date</span>
                          <span>
                            {convertDateToDifferentFormats(
                              orderDetail.orderDate,
                              "dd-mm-yyyy"
                            )}
                          </span>
                        </li>
                        <li>
                          <span>Payment Method </span>
                          <span>{orderDetail.paymentMethod || "--"}</span>
                        </li>
                        <li>
                          <span>Sub Total</span>{" "}
                          <span>
                            {subtotal()
                              ? formatPrice(subtotal(), orderDetail.currency)
                              : "0.00"}
                          </span>
                        </li>
                        <li>
                          <span>Shipping Fee </span>
                          <span>
                            {orderDetail.shippingFee
                              ? formatPrice(
                                  orderDetail.shippingFee,
                                  orderDetail.currency
                                )
                              : "0.00"}
                          </span>
                        </li>
                        <li>
                          <span>Discount</span>{" "}
                          <span>
                            {orderDetail.discountPrice
                              ? formatPrice(
                                  orderDetail.discountPrice,
                                  orderDetail.currency
                                )
                              : "0.00"}
                          </span>
                        </li>

                        <li>
                          <span>Total</span>{" "}
                          <span>
                            {orderDetail.totalPrice
                              ? formatPrice(
                                  orderDetail.totalPrice,
                                  orderDetail.currency
                                )
                              : "0.00"}
                          </span>
                        </li>
                      </ul>
                      <div className="cart-buttons">
                        <div className="pull-right">
                          <Link
                            to={`/${getLanguageCodeFromSession()}/`}
                            className="btn btn-normal btn-sm"
                            id="lbl_ordrhis_continue_ship"
                          >
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Continue Shopping",
                                  "lbl_ordrhis_continue_ship"
                                )
                              : "Continue Shopping"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default OrdersHistoryDetail;
