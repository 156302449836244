import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../../helpers/CommonHelper";
import { LOADER_DURATION } from "../../../../helpers/Constants";
import GlobalEnums from "../../../../helpers/GlobalEnums";
import rootAction from "../../../../stateManagment/actions/rootAction";

const MenuBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  // const [menuData, setmenuData] = useState([
  //   {
  //     title: "Home",
  //     type: "non-sub",
  //   },
  //   {
  //     title: "All Products",
  //     type: "non-sub",
  //   },
  //   {
  //     title: "Categories",
  //     type: "sub",
  //   },
  // ]);
  // const { ref, isComponentVisible, setIsComponentVisible } =
  //   useOutSideClick(false);
  // const mobileSize = useMobileSize();
  // const { t } = useTranslation();
  // const [isOpen, setIsOpen] = useState();
  // const [isSubNavOpen, setIsSubNavOpen] = useState();
  const path = window.location.pathname;

  useEffect(() => {
    const getDataInUseEffect = async () => {
      //--Get language code
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      // const headers = {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // };

      // const param = {
      //   requestParameters: {
      //     PageNo: 1,
      //     PageSize: 100,
      //     recordValueJson: "[]",
      //   },
      // };

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["MegaMenu"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getDataInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  return (
    <>
      {path !== "/Layouts/layout3" && (
        <li>
          <div
            className="mobile-back text-left"
            onClick={() => {
              props.setMenuResponsive(false);
              document.body.style.overflow = "visible";
            }}
          >
            <img src="/images/icon-back.png" alt="back" />
            {/* <i className="fa fa-angle-left " aria-hidden="true"></i> */}
          </div>
        </li>
      )}
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/`}
          className={`dark-menu-item has-submenu ${
            path === "/" ||
            path === "/#" ||
            path === "/" + getLanguageCodeFromSession() + "/"
              ? "active"
              : ""
          }`}
          id="lbl_mgmenu_home"
          onClick={() => {
            props.setMenuResponsive(false);
            document.body.style.overflow = "visible";
          }}
        >
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "Home",
                "lbl_mgmenu_home"
              )
            : "Home"}
        </Link>
      </li>
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/all-products/0/all-categories`}
          className={`dark-menu-item has-submenu ${
            path.includes("all-products") ? "active" : ""
          } `}
          id="lbl_mgmenu_products"
          onClick={() => {
            props.setMenuResponsive(false);
            document.body.style.overflow = "visible";
          }}
        >
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "All Products",
                "lbl_mgmenu_products"
              )
            : "All Products"}
        </Link>
      </li>
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/contact-us`}
          id="lbl_thead_contct"
          className={`dark-menu-item has-submenu ${
            path.includes("contact-us") ? "active" : ""
          }`}
          onClick={() => {
            props.setMenuResponsive(false);
            document.body.style.overflow = "visible";
          }}
        >
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "Contact",
                "lbl_thead_contct"
              )
            : "Contact"}
        </Link>
      </li>
      {/* <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/faq`}
          id="lbl_thead_faq"
          className="dark-menu-item has-submenu"
        >
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                " FAQ's",
                "lbl_thead_faq"
              )
            : "FAQ's"}
        </Link>
      </li>
      <li>
        <Link
          to={`/${getLanguageCodeFromSession()}/about`}
          className="dark-menu-item has-submenu"
          id="lbl_thead_about"
        >
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "About",
                "lbl_thead_about"
              )
            : "About"}
        </Link>
      </li> */}
      {/* {!(
        loginUser !== null &&
        loginUser !== undefined &&
        loginUser.UserID !== undefined &&
        loginUser.UserID > 0
      ) && (
        <li>
          <a
            href={"/" + getLanguageCodeFromSession() + "/login"}
            className="text-dark px-2 d-none d-lg-block"
            onClick={() =>
              navigate("/" + getLanguageCodeFromSession() + "/login")
            }
          >
            Sign in
          </a>
          <button
            className="btn btn-normal d-none d-lg-block"
            onClick={() =>
              navigate("/" + getLanguageCodeFromSession() + "/signup")
            }
          >
            Sign up
          </button>
        </li>
      )} */}
    </>
  );
};

export default MenuBar;
