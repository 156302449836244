export const SET_LOADING = "SET_LOADING";

export const CART_TOTAL = "CART_TOTAL";
export const SET_USER = "SET_USER";
export const SET_GUEST_USER = "SET_GUEST_USER";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const SET_CUSTOMER_CART = "SET_CUSTOMER_CART";
export const EMPTY_CUSTOMER_CART = "EMPTY_CUSTOMER_CART";
export const TOTAL_CART_ITEMS = "TOTAL_CART_ITEMS";
export const SET_CUSTOMER_WISHLIST = "SET_CUSTOMER_WISHLIST";
export const SET_WEBSITE_LOGO = "SET_WEBSITE_LOGO";
export const SET_LANGUAGE_CODE = "SET_LANGUAGE_CODE";
export const SET_LEFT_MENU = "SET_LEFT_MENU";
export const SET_CUSTOMER_COMPARELIST = "SET_CUSTOMER_COMPARELIST";
