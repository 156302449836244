import { useEffect } from "react";
import SearchHeader from "./SearchHeader";
import TopHeader from "./TopHeader";
import { getLanguageCodeFromSession } from "helpers/CommonHelper";

const Navbar = () => {
  const pathname = window.location.pathname;

  const handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 300) {
      if (window.innerWidth < 581)
        document.getElementById("stickyHeader").classList.remove("sticky");
      else document.getElementById("stickyHeader").classList.add("sticky");
    } else document.getElementById("stickyHeader").classList.remove("sticky");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header id="stickyHeader" className="header-section">
        <div className="mobile-fix-option"></div>
        {pathname !== "/" &&
          pathname !== `/${getLanguageCodeFromSession()}/` && <TopHeader />}
        <div className="custom-container">
          <div className="layout-header2 custom-container header-page">
            <SearchHeader />
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
