import React from "react";
import { Row, Col, Container, Media } from "reactstrap";
import myImage from "../../../resources/custom/images/call_img.png";

const ContactBanner = () => {
  return (
    <section className="section-pt-space">
      <div className="contact-banner">
        <Container>
          <Row>
            <Col>
              <div className="contact-banner-contain">
                <div className="contact-banner-img">
                  <Media
                    src={myImage}
                    className="img-fluid"
                    alt="call-banner"
                  />
                </div>
                <div>
                  <h3 style={{ fontWeight: "400" }}>
                    If you have any question please contact us
                  </h3>
                </div>
                {/* <div>
                  <h2> <a href="mailto:support@v1hub.com" style={{ color: "inherit" }}>support@v1hub.com</a></h2>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ContactBanner;
