import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";

const contents = [
  {
    title:
      "We, V1HubShop valued customers' privacy as the most important for us.",
    content: [
      "We are responsible to keep all of the data that collected and not to be shared without permissions of customer.",
    ],
  },
  {
    title: "What personal data that we collect?",
    content: [
      "We collect data that you provided when you use our services such as sign up, login or shipping services. Personal data also includes anonymous data that is linked to information that can be used to directly or indirectly identify you. Personal data does not include data that has been irreversibly anonymized or aggregated so that it can no longer enable us, whether in combination with other information or otherwise, to identify you.",
    ],
  },
  {
    title:
      "Data that you provided & data that we collected when you are using our services.",
    list: [
      "We collect personal data that you provide when using our services such as when sign up and checkout. If there is a purchase, we also will collect your personal data includes your payment data, such as your credit or debit card number and other card information, and other account and authentication information, as well as billing, shipping, and contact details.",
      "Also, we may collect data regarding type of your device, your device’s unique identifier and even IP address of your device the computers, phones, or other devices on which you install or access our products or services. Where available, our services may use GPS, your IP address, and other technologies to determine a device’s approximate location. This is to improve our services.",
    ],
  },
  {
    title: "Why we need your personal data?",
    content: [
      "We collected personal data to ensure that we have improvement in our services, we heard your voices, we are able to inform you in any promotional events or any update of your orders",
      "For customers that purchased our items, we required your personal data to fulfill shipping requirement.",
    ],
  },
  {
    title: "Your Rights",
    content: [
      "We are responsible to keep all the information that we collected. However, it is your rights to change and delete your personal information. You have your rights to complain if there are any inappropriate activities that we done.",
    ],
  },
  {
    title: "Cookies Definition",
    content: [
      "Cookies are small pieces of text used to store information on web browsers. Cookies are widely used to store and receive identifiers and other information on computers, phones, and other devices. We also use other technologies, including data we store on your web browser or device, identifiers associated with your device, and other software, for similar purposes. In this Cookie Statement, we refer to all of these technologies as “cookies.”",
    ],
  },
  {
    title: "Use of Cookies",
    list: [
      "We make certain personal data available to strategic partners that work with us to provide our products and services or help us market to customers. Personal data will only be shared by us with these companies in order to provide or improve our products, services, and advertising; it will not be shared with third parties for their own marketing purposes without your prior express consent.",
      "We and our Third-Party Service Providers use cookies and other similar technologies (“Cookies”) in order for us to provide our Service and ensure that it performs properly, to analyze our performance and marketing activities, and to personalize your experience.",
      "Please note that we do not change our practices in response to a “Do Not Track” signal in the HTTP header from a browser or mobile application, however, most browsers allow you to control cookies, including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser.",
    ],
  },
  {
    title: "Third-Party Websites and Services",
    content: [
      "Our website probably will provide access to any Third-Party Websites and Services especially for shipping services, but we are not responsible with that. To ensure secure of your information, we suggest you read policy, terms& condition for Third-Party Websites and Services before you proceed to the next step.",
      "Any changes are allowed to be made by V1HubShop in legal and logical reasons.",
    ],
  },
];

const PrivacyPolicy = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Privacy Policy</title>
        <meta name="description" content={siteTitle + " - Privacy Policy"} />
        <meta name="keywords" content="Privacy Policy"></meta>
      </Helmet>

      <SiteBreadcrumb title="Privacy Policy" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{content.title}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {item}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
