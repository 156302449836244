import { Helmet } from "react-helmet";
import { Col, Row } from "reactstrap";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { useState } from "react";

const contents = [
  {
    content: [
      "Almost products on V1HubShop are manufactured on demand, unless indicated otherwise, which means there is no return policy, however, if a product is broken in shipping we will remanufacture it at no cost to you.",

      "We have a 30-day return policy for stock products, where applicable, which means you have 30 days after receiving your item to request a return.",

      "To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.",

      "To start a return, you can contact us here.  Items sent back to us without first requesting a return will not be accepted.",

      "You can always contact us for any return questions here",
    ],
  },

  {
    title: "Damages and issues",
    content: [
      "Please inspect your order upon reception and contact us immediately if the item is defective, or damaged or if you receive the wrong item so that we can evaluate the issue and make it right.",
    ],
  },

  {
    title: "Refunds",
    content: [
      "We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method. Please remember it can take some time for your bank or credit card company to process and post the refund too.",
    ],
  },
];

const RefundPolicy = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Refund Policy</title>
        <meta name="description" content={siteTitle + " - Refund Policy"} />
        <meta name="keywords" content="Refund Policy"></meta>
      </Helmet>

      <SiteBreadcrumb title="Refund Policy" parent="Home" />

      <section className="about-page  mt-2 mt-md-4">
        <div className="custom-container" style={{ maxWidth: "900px" }}>
          <Row>
            {contents.map((content, index) => (
              <Col xs="12" className="mb-2 mt-1">
                <h4 className="mb-2">{content.title}</h4>
                {content.list && content.list.length > 0 ? (
                  <ul style={{ listStyleType: "disc", paddingLeft: "32px" }}>
                    {content.list.map((item, index) => (
                      <li
                        key={index}
                        className="mb-2"
                        style={{ display: "list-item" }}
                      >
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
                {content.content &&
                  content.content.length > 0 &&
                  content.content.map((item, index) => (
                    <p className="mb-2" key={index}>
                      {item}
                    </p>
                  ))}
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;
